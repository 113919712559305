import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import ProblemsCard from 'src/components/shared/Card/ProblemsCard'
import ProblemsTitle from 'src/components/shared/Title/ProblemsTitle'
import ProblemsCardWrapper from 'src/components/shared/Wrapper/ProblemsCardWrapper'

const Problems: React.FCX = () => {
  const problemsData = [
    'ポータル経由の集客だけでは\n物足りない',
    '予約後の来店・成約率が低い',
    '閑散期の売り上げを\n底上げしたい'
  ]
  return (
    <Wrapper>
      <ProblemsTitle>
        スムーズは賃貸仲介事業者様のお困りごとを解決します
      </ProblemsTitle>
      <ProblemsCardWrapper>
        {problemsData.map((d) => (
          <ProblemsCard key={d}>{d}</ProblemsCard>
        ))}
      </ProblemsCardWrapper>
    </Wrapper>
  )
}

export default Problems
