import React from 'react'
import styled from 'styled-components'
import Img from 'src/components/shared/Img'
import { topcatchStyles } from 'src/components/shared/Topcatch/styles'
import { color } from 'src/lib/styles/variable'

const Topcatch: React.FCX = ({ className }) => {
  return (
    <div className={`${className} ${className}--for_lp`}>
      <Img filePath="topcatch/topcatch-lp.jpg" />
      <div className={`${className}-text`}>
        <h1 className={`${className}-text__heading u-font-midashigo`}>
          スムーズは
          <br />
          導入費・維持費0円の
          <br />
          送客サービスです
        </h1>
      </div>
    </div>
  )
}

export default styled(Topcatch)`
  ${topcatchStyles}
  &-text {
    > h1 {
      color: ${color.main};
    }
  }
`
