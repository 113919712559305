import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import Title from 'src/components/shared/Title'
import MeritCard from 'src/components/shared/Card/MeritCard'
import { MeritCardWrapper } from 'src/components/shared/Wrapper/CardWrapper'

const Merit: React.FCX = () => {
  const meritList = [
    {
      img: '1.svg',
      title: '高い成約率',
      sentence:
        'スムーズ利用者は、弊社提携先の仲介事業者でしか契約ができないので、成約率が高いです。'
    },
    {
      img: '2.svg',
      title: '成果報酬型',
      sentence:
        '送客時ではなく、成約時に報酬が発生するシステムなので、導入のリスクがありません。'
    },
    {
      img: '3.svg',
      title: '閑散期の売上確保',
      sentence:
        '分割払いにより消費者の引越し時期が早まり、閑散期の売り上げ安定化が見込めます。'
    }
  ]
  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="スムーズ導入のメリット"
        sub="MERITS"
      />
      <MeritCardWrapper>
        {meritList.map((li) => (
          <MeritCard
            key={li.title}
            title={li.title}
            sentence={li.sentence}
            img={li.img}
          />
        ))}
      </MeritCardWrapper>
    </Wrapper>
  )
}

export default Merit
