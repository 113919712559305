/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormEvent, useState, useMemo } from 'react'
import axios from 'axios'
import { SMOOTH_API_URL } from '../../../../../config'

export const usePartnerForm = () => {
  const [value, setValue] = useState({
    type: 'CORP_PARTNER',
    name: null,
    email: null,
    company: null,
    requirement: null,
    remarks: null
  })

  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement
    setValue((v) => ({
      ...v,
      [target.name]: target.value
    }))
  }

  const handleSubmit = async () => {
    await axios.post(`${SMOOTH_API_URL}/public_api/inquiry_emails`, value).catch((err) => {
      // eslint-disable-next-line no-alert
      alert(err)
      throw new Error(err)
    })
    // eslint-disable-next-line no-alert
    alert('送信に成功しました')
  }

  const isValid = useMemo(() => {
    return !!(value.name && value.email && value.company && value.requirement)
  }, [value.company, value.email, value.name, value.requirement])

  return {
    handleChange,
    handleSubmit,
    value,
    isValid
  }
}
