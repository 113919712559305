import React from 'react'
import { Head } from 'src/components/layout/head'
import { DefaultLayout } from 'src/components/layout/default'
import Partner from 'src/components/pages/Partner'
import { partnerPageHeaderItems } from 'src/lib/data/headerItems'

const PartnerPage: React.FCX = () => {
  return (
    <div className="partner-page">
      <Head title="仲介事業者様向け | 株式会社スムーズ" />
      <DefaultLayout headerItems={partnerPageHeaderItems} smoothScroll>
        <Partner />
      </DefaultLayout>
    </div>
  )
}

export default PartnerPage
